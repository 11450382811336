div<template>
  <div class="cart">
    <van-nav-bar title="购物车" safe-area-inset-top :border="false" fixed>
      <template #right>
        <div class="clear-out" @click="handleClearCart">清空</div>
        <div class="mine" @click="handleLinkToMerchantSelect">我的</div>
      </template>
    </van-nav-bar>
    <van-skeleton title :row="6" :loading="loading">
      <div class="cart-main">
        <!--整的套餐未修改的-->
        <div
          class="suit-box"
          v-for="suite in state.suitMaterialList.filter(
            (i) => i.hasUpdate === 0 && i.hasSuit === 1 && i.hasDeleted === 0
          )"
          :key="suite.suitId"
        >
          <div class="shopmain">
            <div class="suit-head-box">
              <van-checkbox
                v-model="suite.checked"
                @change="suiteItemCheckChange(suite)"
                style="width: 50%;"
              >
                <div class="goods-set">{{ suite.suitName }}</div>
              </van-checkbox>
              <div
                class="delete"
                style="color:red"
                @click.stop="handleDeleteSuit(suite)"
              >
                删除
              </div>
            </div>
            <van-checkbox
              :name="item"
              v-model="suite.checked"
              label-disabled
              v-for="(item, i) in suite.suitDetailsList"
              :key="i"
            >
              <div class="goods-box">
                <div class="pic-box">
                  <img
                    v-if="item.materialPicUrl"
                    :src="item.materialPicUrl"
                    alt=""
                  />
                  <img
                    v-else
                    src="https://wca.ujiaku.com/upload/app_defaultImg/noPic.png"
                  />
                </div>
                <div class="desc">
                  <div class="name">
                    {{ item.materialName }} {{ item.itemNo }}
                  </div>
                  <div class="specs">规格:{{ item.specs }}</div>
                  <div class="price">
                    <div>￥{{ item.sellingPrice }}</div>
                    <div class="desc-number">x{{ item.number }}</div>
                  </div>
                </div>
                <div class="action">
                  <div style="color:red" @click.stop="handleDelete(item)">
                    删除
                  </div>
                </div>
              </div>
            </van-checkbox>
          </div>
        </div>
        <!--整的套餐修改过的-->
        <div
          class="suit-box"
          v-for="suite in state.suitMaterialList.filter(
            (i) => i.hasUpdate === 0 && i.hasSuit === 0 && i.hasDeleted === 0
          )"
          :key="suite.suitId"
        >
          <div class="shopmain">
            <div class="suit-head-box">
              <van-checkbox
                v-model="suite.checked"
                @change="suitUpdatedItemCheckChange(suite)"
                style="width: 50%;"
              >
                <div class="goods-set">{{ suite.suitName }}</div>
              </van-checkbox>
              <div
                class="delete"
                style="color:red"
                @click.stop="handleDeleteSuit(suite)"
              >
                删除
              </div>
            </div>
            <van-checkbox
              :name="item"
              v-model="item.checked"
              label-disabled
              v-for="(item, i) in suite.suitDetailsList"
              @change="
                (val) => {
                  handleUpdatedSuitItemCheckChange(suite, val, item);
                }
              "
              :key="i"
            >
              <div class="goods-box">
                <div class="pic-box">
                  <img
                    v-if="item.materialPicUrl"
                    :src="item.materialPicUrl"
                    alt=""
                  />
                  <img
                    v-else
                    src="https://wca.ujiaku.com/upload/app_defaultImg/noPic.png"
                  />
                </div>
                <div class="desc">
                  <div class="name">
                    {{ item.materialName }} {{ item.itemNo }}
                  </div>
                  <div class="specs">规格:{{ item.specs }}</div>
                  <div class="price">
                    <div>￥{{ item.sellingPrice }}</div>
                    <van-stepper
                      v-model="item.number"
                      min="1"
                      disable-input
                      button-size="14px"
                      @change="
                        (val) => {
                          handleSingleChange(val, item.cartId);
                        }
                      "
                    />
                  </div>
                </div>
                <div class="action">
                  <div style="color:red" @click.stop="handleDelete(item)">
                    删除
                  </div>
                </div>
              </div>
            </van-checkbox>
          </div>
        </div>

        <!--套餐已过期的-->
        <div
          class="suit-box"
          v-for="suite in state.suitMaterialList.filter(
            (i) => i.hasUpdate === 1 && i.hasDeleted === 0
          )"
          :key="suite.suitId"
        >
          <div class="shopmain">
            <div class="shopmain-head">
              <van-checkbox disabled>
                <div class="goods-set">{{ suite.suitName }}</div>
              </van-checkbox>
              <div class="outdate">
                套装已更新，请点击
                <span
                  style="color:#FE3A30"
                  @click="handleReAddSuitToCart(suite)"
                  >重新获取</span
                >
              </div>
            </div>

            <van-checkbox
              disabled
              label-disabled
              v-for="(item, i) in suite.suitDetailsList"
              :key="i"
            >
              <div class="goods-box">
                <div class="pic-box">
                  <img
                    v-if="item.materialPicUrl"
                    :src="item.materialPicUrl"
                    alt=""
                  />
                  <img
                    v-else
                    src="https://wca.ujiaku.com/upload/app_defaultImg/noPic.png"
                  />
                </div>
                <div class="desc">
                  <div class="name">
                    {{ item.materialName }} {{ item.itemNo }}
                  </div>
                  <div class="specs">规格:{{ item.specs }}</div>
                  <div class="desc-number">x{{ item.number }}</div>
                </div>
                <div class="action">
                  <div style="color:red" @click.stop="handleDelete(item)">
                    删除
                  </div>
                </div>
              </div>
            </van-checkbox>
          </div>
        </div>

        <!--单购的-->
        <div class="suit-box" v-if="state.singleMaterialList.length">
          <div class="shopmain">
            <div class="singleCheckAll">
              <div class="goods-set">单购</div>
            </div>
            <van-checkbox
              @change="handleSingleCheckChange(item)"
              v-model="item.checked"
              label-disabled
              v-for="(item, i) in state.singleMaterialList.filter(
                (o) => o.hasDeleted == 0
              )"
              :key="i"
            >
              <div class="goods-box">
                <div class="pic-box">
                  <img
                    v-if="item.materialPicUrl"
                    :src="item.materialPicUrl"
                    alt=""
                  />
                  <img
                    v-else
                    src="https://wca.ujiaku.com/upload/app_defaultImg/noPic.png"
                  />
                </div>
                <div class="desc">
                  <div class="name">
                    {{ item.materialName }} {{ item.itemNo }}
                  </div>
                  <div class="specs">规格:{{ item.specs }}</div>
                  <div class="price">
                    <div>￥{{ item.sellingPrice }}</div>
                    <van-stepper
                      v-model="item.number"
                      min="1"
                      disable-input
                      @change="
                        (val) => {
                          handleSingleChange(val, item.cartId);
                        }
                      "
                    />
                  </div>
                </div>
                <div class="action">
                  <div style="color:red" @click.stop="handleDelete(item)">
                    删除
                  </div>
                </div>
              </div>
            </van-checkbox>
          </div>
        </div>
        <!--已下架的单购和套装-->
        <div
          v-if="
            state.suitMaterialList.find((i) => i.hasDeleted === 1) ||
              state.singleMaterialList.find((i) => i.hasDeleted === 1)
          "
        >
          <div class="shopmain">
            <div class="shopmain-head">
              <div class="goods-set">已下架宝贝</div>
              <div class="outdate">
                <span style="color:#FE3A30" @click="handleClearDeletedGoods"
                  >清空已下架宝贝</span
                >
              </div>
            </div>
            <div
              class="suit-box"
              v-for="suite in state.suitMaterialList.filter(
                (i) => i.hasDeleted === 1
              )"
              :key="suite.suitId"
            >
              <div class="shopmain-head">
                <div class="goods-set">{{ suite.suitName }}</div>
              </div>
              <van-checkbox
                disabled
                label-disabled
                v-for="(item, i) in suite.suitDetailsList"
                :key="i"
              >
                <div class="goods-box">
                  <div class="pic-box">
                    <img
                      v-if="item.materialPicUrl"
                      :src="item.materialPicUrl"
                      alt=""
                    />
                    <img
                      v-else
                      src="https://wca.ujiaku.com/upload/app_defaultImg/noPic.png"
                    />
                  </div>
                  <div class="desc">
                    <div class="name">
                      {{ item.materialName }} {{ item.itemNo }}
                    </div>
                    <div class="specs">规格:{{ item.specs }}</div>
                    <div class="desc-number">x{{ item.number }}</div>
                  </div>
                  <div class="action">
                    <div style="color:red" @click.stop="handleDelete(item)">
                      删除
                    </div>
                  </div>
                </div>
              </van-checkbox>
            </div>
            <div
              class="shopmain-head"
              v-if="state.singleMaterialList.find((i) => i.hasDeleted === 1)"
            >
              <div class="goods-set">单购</div>
            </div>
            <van-checkbox
              disabled
              label-disabled
              v-for="(item, i) in state.singleMaterialList.filter(
                (i) => i.hasDeleted === 1
              )"
              :key="i"
            >
              <div class="goods-box">
                <div class="pic-box">
                  <img
                    v-if="item.materialPicUrl"
                    :src="item.materialPicUrl"
                    alt=""
                  />
                  <img
                    v-else
                    src="https://wca.ujiaku.com/upload/app_defaultImg/noPic.png"
                  />
                </div>
                <div class="desc">
                  <div class="name">
                    {{ item.materialName }} {{ item.itemNo }}
                  </div>
                  <div class="specs">规格:{{ item.specs }}</div>
                  <div class="desc-number">x{{ item.number }}</div>
                </div>
                <div class="action">
                  <div style="color:red" @click.stop="handleDelete(item)">
                    删除
                  </div>
                </div>
              </div>
            </van-checkbox>
          </div>
        </div>
      </div>
      <div
        v-show="
          state.singleMaterialList.length === 0 &&
            state.suitMaterialList.length === 0
        "
        class="shopping"
      >
        <div></div>
        <p>暂无商品</p>
      </div>
      <div class="total-row">
        <div class="check">
          <van-checkbox v-model="checkAll">全选</van-checkbox>
        </div>
        <div class="nums">
          已选{{
            state.selectedSingleMaterialList.length +
              state.selectedSuitMaterialList.length
          }}件
        </div>
        <div class="total-price">
          <span>总计:</span>
          <span style="color:#FE3A30">￥{{ totalPrice }}</span>
        </div>
      </div>
    </van-skeleton>
    <BottomTabbar />
  </div>
</template>

<script setup>
import { Toast } from "vant";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  addMaterialToShopCart,
  calculateMaterialofShopCart,
  clearCartDeleteMaterial,
  clearShopCartMaterial,
  deleteShopCartMaterial,
  editShopCartMaterialNumber,
  getShopCartMaterialInfo,
} from "../../api/common";
import BottomTabbar from "../../components/bottomTabbar.vue";

const router = useRouter();
const store = useStore();
const token = computed(() => store.getters.token);
const materialId = computed(() => store.getters.materialId);
const merchantId = computed(() => store.getters.merchantId);
const totalPrice = ref(0);
const checkAll = ref(false);
const loading = ref(true);
const singleCheckedList = ref([]);

const state = reactive({
  suitMaterialList: [],
  singleMaterialList: [],
  selectedSingleMaterialList: [],
  selectedSuitMaterialList: [],
});
watch(checkAll, (newVal) => {
  if (newVal === true) {
    console.log("checkAll=========true");
    //选中全部单购的
    state.singleMaterialList.forEach((i) => (i.checked = true));
    //选中套装未修改的
    state.suitMaterialList
      .filter((i) => i.hasUpdate === 0 && i.hasSuit === 1 && i.hasDeleted === 0)
      .forEach((item) => (item.checked = true));
    //选中套装修改过的
    state.suitMaterialList
      .filter((i) => i.hasUpdate === 0 && i.hasSuit === 0 && i.hasDeleted === 0)
      .forEach((item) =>
        item.suitDetailsList.forEach((o) => (o.checked = true))
      );
  } else {
    console.log("checkAll=========false");
    let isAllSingleChecked = state.singleMaterialList.every(
      (i) => i.checked == true
    );
    //套装未修改过的
    let isAllSuiteNoUpdate = state.suitMaterialList
      .filter((i) => i.hasUpdate === 0 && i.hasSuit === 1 && i.hasDeleted === 0)
      .every((i) => i.checked == true);
    //套装修改过的
    let isAllSuiteUpdated = state.suitMaterialList
      .filter((i) => i.hasUpdate === 0 && i.hasSuit === 0 && i.hasDeleted === 0)
      .every((item) => item.suitDetailsList.every((o) => o.checked == true));

    if (isAllSuiteNoUpdate && isAllSuiteUpdated && isAllSingleChecked) {
      //取消选中全部单购的
      state.singleMaterialList.forEach((i) => (i.checked = false));
      //选中套装未修改的
      state.suitMaterialList
        .filter(
          (i) => i.hasUpdate === 0 && i.hasSuit === 1 && i.hasDeleted === 0
        )
        .forEach((item) => (item.checked = false));
      //取消套装修改过的
      state.suitMaterialList
        .filter(
          (i) => i.hasUpdate === 0 && i.hasSuit === 0 && i.hasDeleted === 0
        )
        .forEach((item) =>
          item.suitDetailsList.forEach((o) => (o.checked = false))
        );
    }
  }
});
watch(
  [
    () => state.selectedSingleMaterialList,
    () => state.selectedSuitMaterialList,
  ],
  () => {
    let isAllSingleChecked = state.singleMaterialList.every(
      (i) => i.checked == true
    );
    //套装未修改过的
    let isAllSuiteNoUpdate = state.suitMaterialList
      .filter((i) => i.hasUpdate === 0 && i.hasSuit === 1 && i.hasDeleted === 0)
      .every((i) => i.checked == true);
    //套装修改过的
    let isAllSuiteUpdated = state.suitMaterialList
      .filter((i) => i.hasUpdate === 0 && i.hasSuit === 0 && i.hasDeleted === 0)
      .every((item) => item.suitDetailsList.every((o) => o.checked == true));

    if (
      isAllSuiteNoUpdate &&
      isAllSuiteUpdated &&
      isAllSingleChecked &&
      (state.singleMaterialList.length > 0 || state.suitMaterialList.length > 0)
    ) {
      console.log("是这样的");
      checkAll.value = true;
    } else {
      checkAll.value = false;
    }
    calcTotalPrice();
  },
  { deep: true }
);

let reqList = [];
const calcTotalPrice = () => {
  let param = {
    token: token.value,
    materialStockId: materialId.value,
    suitIdList: state.selectedSuitMaterialList,
    cartIdList: state.selectedSingleMaterialList,
  };
  let res = calculateMaterialofShopCart(param);
  reqList.push(res);
  res.then((data) => {
    if (res === reqList[reqList.length - 1]) {
      if (data) {
        totalPrice.value = data.totalPrice;
        reqList = [];
      }
    }
  });
};
const suiteItemCheckChange = (suit) => {
  if (
    suit.checked === true &&
    !state.selectedSuitMaterialList.includes(suit.suitId)
  ) {
    state.selectedSuitMaterialList.push(suit.suitId);
  }
  if (
    suit.checked === false &&
    state.selectedSuitMaterialList.includes(suit.suitId)
  ) {
    state.selectedSuitMaterialList.splice(
      state.selectedSuitMaterialList.indexOf(suit.suitId),
      1
    );
  }
};

//修改过的套装
const suitUpdatedItemCheckChange = (suit) => {
  if (suit.checked == true) {
    suit.suitDetailsList.forEach((i) => (i.checked = true));
  }
  if (suit.checked == false) {
    if (suit.suitDetailsList.every((i) => i.checked === true)) {
      suit.suitDetailsList.forEach((i) => (i.checked = false));
    }
  }
};

const updatedSuiteItemCheckChange = (val, suit) => {
  if (val == true) {
    console.log("suit", suit);
    suit.suitDetailsList.forEach((element) => {
      element.checked = true;
    });
  } else {
    let temp = suit.suitDetailsList.filter((i) => i.checked === true);
    if (temp.length == suit.suitDetailsList.length) {
      suit.suitDetailsList.forEach((element) => {
        element.checked = false;
      });
    }
  }
};

const handleUpdatedSuitItemCheckChange = (suit, val, item) => {
  let temp = suit.suitDetailsList.find(
    (i) => i.checked === undefined || i.checked === false
  );
  if (temp) {
    suit.checked = false;
  } else {
    suit.checked = true;
  }
  if (val === true && !state.selectedSingleMaterialList.includes(item.cartId)) {
    state.selectedSingleMaterialList.push(item.cartId);
  }
  if (val === false && state.selectedSingleMaterialList.includes(item.cartId)) {
    state.selectedSingleMaterialList.splice(
      state.selectedSingleMaterialList.indexOf(item.cartId),
      1
    );
  }
  console.log(state.selectedSingleMaterialList);
};

const handleReAddSuitToCart = async (suit) => {
  console.log(suit);
  //先移除
  await deleteShopCartMaterial({
    token: token.value,
    suitId: suit.suitId,
    materialStockId: materialId.value,
  });
  //重新添加到购物车
  let param = {
    token: token.value,
    materialStockId: materialId.value,
    suitId: suit.suitId,
  };
  let data = await addMaterialToShopCart(param);
  //重新获取购物车信息
  await loadCartInfo();
};

const handleSingleCheckChange = (item) => {
  if (
    item.checked === true &&
    !state.selectedSingleMaterialList.includes(item.cartId)
  ) {
    state.selectedSingleMaterialList.push(item.cartId);
  }
  if (
    item.checked === false &&
    state.selectedSingleMaterialList.includes(item.cartId)
  ) {
    state.selectedSingleMaterialList.splice(
      state.selectedSingleMaterialList.indexOf(item.cartId),
      1
    );
  }
  console.log(state.selectedSingleMaterialList);
};
const loadCartInfo = async () => {
  Toast.loading({
    message: "加载中",
    duration: 200,
  });
  loading.value = true;
  let data = await getShopCartMaterialInfo({
    token: token.value,
    materialStockId: materialId.value,
  });
  state.singleMaterialList = data.singleMaterialList;
  state.suitMaterialList = data.suitMaterialList;
  state.selectedSingleMaterialList = [];
  state.selectedSuitMaterialList = [];
  loading.value = false;
  if (
    state.singleMaterialList.length === 0 &&
    state.suitMaterialList.length === 0
  ) {
    store.commit("SET_EMPTYCART", true);
  }
  Toast.clear(true);
};
const handleSingleChange = async (val, cartId) => {
  await editShopCartMaterialNumber({
    token: token.value,
    cartId: cartId,
    number: val,
  });
  await calcTotalPrice();
};
const handleDelete = async (item) => {
  Toast.loading();
  let data = await deleteShopCartMaterial({
    token: token.value,
    cartId: item.cartId,
    materialStockId: materialId.value,
  });
  state.selectedSuitMaterialList = [];
  loadCartInfo();
};
const handleDeleteSuit = async (item) => {
  Toast.loading();
  let data = await deleteShopCartMaterial({
    token: token.value,
    suitId: item.suitId,
    materialStockId: materialId.value,
  });
  state.selectedSuitMaterialList = [];
  loadCartInfo();
};
const handleLinkToMerchantSelect = () => {
  router.push("/merchantSelect");
};
const handleClearCart = async () => {
  let data = await clearShopCartMaterial({
    token: token.value,
    materialStockId: materialId.value,
  });
  if (data) {
    Toast.success({ message: "清空成功" });
    checkAll.value = false;
    totalPrice.value = 0;
    state.selectedSingleMaterialList = [];
    state.selectedSuitMaterialList = [];
    loadCartInfo();
    store.commit("SET_EMPTYCART", true);
  }
};

const handleClearDeletedGoods = async () => {
  let param = {
    token: token.value,
    materialStockId: materialId.value,
  };
  param.suitIdList = state.suitMaterialList
    .filter((item) => item.hasDeleted === 1)
    .map((o) => o.suitId);
  param.cartIdList = state.singleMaterialList
    .filter((item) => item.hasDeleted === 1)
    .map((o) => o.cartId);
  console.log(param);
  await clearCartDeleteMaterial(param);
  await loadCartInfo();
};

loadCartInfo();
</script>

<style lang="scss" scoped>
.cart {
  max-width: 375px;
  overflow-x: hidden;
  .total-row {
    background: #fff;
    display: flex;
    position: fixed;
    align-items: center;
    left: 0;
    bottom: 50px;
    height: 50px;
    width: 95%;
    color: #696974;
    font-size: 14px;
    text-align: left;
    padding: 0 12px;
    .check {
      flex: 1;
    }
    .total-price {
      padding: 0 14px;
      font-family: "DIN";
    }
  }
  .clear-out {
    color: #fe3a30;
    font-weight: 600;
    font-size: 14px;
    margin-right: 10px;
  }
  .mine {
    font-weight: 600;
    font-size: 14px;
    color: #4d6ff0;
  }
  .cart-main {
    text-align: left;
    margin: 50px 0px 100px 0px;
    background: rgb(250, 250, 250);
    border-radius: 8px;

    .suit-box {
      padding: 6px 12px;
      &:nth-child(1) {
        padding: 12px 12px 6px 12px;
      }
    }

    .singleCheckAll {
      padding-left: 28px;
      background: #fff;
    }
    .shopmain {
      padding: 6px 10px;
      border-radius: 8px;
      margin-bottom: 10;
      background: #fff;
      .suit-head-box {
        display: flex;
        background: #fff;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        .delete {
          width: 30px;
          text-align: left;
          margin-right: 3px;
        }
      }
      .shopmain-head {
        display: flex;
        background: #fff;
        justify-content: space-between;
        align-items: center;
        .goods-set {
          width: 125px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .outdate {
          color: #171725;
          font-size: 14px;
        }
      }
    }
    .goods-set {
      color: #171725;
      font-weight: 500;
      font-size: 16px;
      background: #fff;
      padding: 9px 4px;
    }
    .goods-box {
      display: flex;
      font-size: 12px;
      background: #fff;
      .pic-box {
        border-radius: 4px;
      }
      img {
        width: 94px;
        height: 94px;
        object-fit: cover;
        border-radius: 4px;
      }
      .desc {
        width: 159px;
        flex: 1;
        display: flex;
        flex-direction: column;
        margin: 0 10px 0 8px;
        .desc-number {
          color: #696974;
          font-size: 14px;
        }
        .name {
          font-size: 12px;
          color: #171725;
        }
        .specs {
          font-size: 10px;
          color: #92929d;
          margin: 8px 0 10px 0;
        }
        .price {
          color: #fe3a30;
          font-size: 16px;
          display: flex;
          justify-content: space-between;
          position: relative;

          div:first-child {
            flex: 1;
            font-family: "DIN";
          }
        }
      }
      .action {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}
</style>
<style lang="scss">
.cart {
  .van-checkbox {
    background: #fff;
  }
  .van-checkbox__label {
    background: #fff;
  }
  .shopmain-head {
    .van-checkbox {
      max-width: 165px;
    }
  }
  .van-stepper__input {
    width: 40px;
    height: 14px;
  }
  .van-stepper__minus {
    height: 14px;
    width: 14px;
  }
  .van-stepper__plus {
    height: 14px;
    width: 14px;
  }
}
</style>
